.content{
    width: 100%;
    height: 100vh;
    background-image:url(../../images/bg.jpeg)
}
.box{
    width: 800px;
    height: 300px !important;
    margin:0 auto;
    padding-top: 300px;
    overflow: hidden;
}
.line{
    border-left: 2px solid #033562;
    height: 230px;
    width: 2px;
}
.login{
    color: white;
    text-align: center;
    font-size:20px;
    font-weight: bold;
}
/* :global .inputlogin{
    color:white ;
     background-color:transparent !important;
} */
.anticon.ant-input-password-icon{
    color: white !important;
}