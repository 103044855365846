/* @import '~antd/dist/reset.css'; */
.ant-empty{
    margin: 0 !important;
}
.ant-input{
    background-color:transparent !important;
}

.anticon.ant-input-password-icon{
    color: white !important;
}
.ant-layout-sider{
    background:#001628!important
}
.ant-radio-wrapper{
    color: white !important;
}
.ant-card .ant-card-body{
    padding: 18px;
}